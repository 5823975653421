import axios from "axios";
import { toast } from "react-toastify";
import {
  ACCOUNT_URL,
  GET_USER_URL,
  LOGIN_USER_URL,
  UPDATE_EMAIL_URL,
  UPDATE_PASSWORD_URL,
} from "../api/adminApi";
import {
  addAccountFailed,
  addAccountRequest,
  addAccountSuccess,
  getAccountFailed,
  getAccountRequest,
  getAccountSuccess,
  loginAdminFailed,
  loginAdminRequest,
  loginAdminSuccess,
  updateAccountFailed,
  updateAccountRequest,
  updateAccountSuccess,
} from "../reducers/adminReducer";

export const loginUser = (body, dispatch) => {
  dispatch(loginAdminRequest());
  axios
    .post(LOGIN_USER_URL, body)
    .then((res) => {
      dispatch(loginAdminSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(loginAdminFailed());
    });
};

export const getUser = (body, dispatch) => {
  dispatch(loginAdminRequest());
  axios
    .post(GET_USER_URL, body)
    .then((res) => {
      dispatch(loginAdminSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(loginAdminFailed());
    });
};

export const getAccount = (token, dispatch) => {
  dispatch(getAccountRequest());
  axios
    .get(ACCOUNT_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getAccountSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getAccountFailed());
    });
};

export const addAccount = (token, body, closeModal, dispatch) => {
  dispatch(addAccountRequest());
  axios
    .post(ACCOUNT_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      toast(res.data.message, { type: "success" });
      dispatch(addAccountSuccess());
      getAccount(token, dispatch);
      closeModal();
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addAccountFailed());
    });
};

export const updateAccount = (token, id, body, closeModal, dispatch) => {
  dispatch(updateAccountRequest());
  axios
    .put(`${ACCOUNT_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      closeModal();
      toast(res.data.message, { type: "success" });
      dispatch(updateAccountSuccess());
      getAccount(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(updateAccountFailed());
    });
};

export const updateEmail = (token, id, body, closeModal, dispatch) => {
  dispatch(updateAccountRequest());
  axios
    .put(`${UPDATE_EMAIL_URL}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      closeModal();
      toast(res.data.message, { type: "success" });
      dispatch(updateAccountSuccess());
      getUser({ token }, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(updateAccountFailed());
    });
};

export const updatePassword = (token, id, body, closeModal, dispatch) => {
  dispatch(updateAccountRequest());
  axios
    .put(`${UPDATE_PASSWORD_URL}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      closeModal();
      toast(res.data.message, { type: "success" });
      dispatch(updateAccountSuccess());
      getUser({ token }, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(updateAccountFailed());
    });
};
