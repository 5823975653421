import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import LogoImage from "../../../images/logo/casanova.webp";
import "./styles.css";

const Header = () => {
  return (
    <header>
      <div className="headerContainer">
        <div className="headerLogo">
          <Link to="/">
            <Image src={LogoImage} alt="Casanova" width={132} height={80} />
          </Link>
        </div>
        <nav className="headerNav">
          <Link className="headerNavLink" to="/">
            Home
          </Link>
          <Link className="headerNavLink" to="/orders">
            Commandes
          </Link>
          <Link className="headerNavLink" to="/categories">
            Catégories
          </Link>
          <Link className="headerNavLink" to="/articles">
            Articles
          </Link>
          <Link className="headerNavLink" to="/utilisateurs">
            Utilisateurs
          </Link>
          <Link className="headerNavLink" to="/profile">
            Profil
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
