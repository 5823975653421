import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addArticle,
  addArticleImage,
  getArticle,
  updateArticle,
} from "../../../redux/actions/articleActions";
import "./styles.css";

function ArticleModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { categories } = useSelector((state) => state.categoryReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [articleImage, setArticleImage] = useState(null);
  const [isDriver, setIsDriver] = useState(false);

  useEffect(() => {
    if (toggleModal.category) {
      getArticle(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            image: res.data.image,
            daily: res.data.price.daily,
            weekly: res.data.price.weekly,
            monthly: res.data.price.monthly,
            maxHeight: res.data.maxHeight,
            maxWeight: res.data.maxWeight,
            delivery: res.data.price.delivery,
            deposit: res.data.price.deposit,
            active: res.data.active,
            category: res.data.category,
            isDriver: res.data.isDriver,
            driver: res.data.driver,
          };
          setIsDriver(res.data.isDriver);
          setArticleImage(res.data.image);
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onChangeCategoryImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addArticleImage(token, formData)
      .then((res) => {
        let fields = form.getFieldsValue();
        fields = {
          ...fields,
          image: res.data,
        };
        form.setFieldsValue(fields);
        setArticleImage(res.data);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: "error" });
      });
  };

  const onChangeIsDriver = (value) => {
    let fields = form.getFieldsValue();
    fields = {
      ...fields,
      isDriver: value,
    };
    form.setFieldsValue(fields);
    setIsDriver(value);
  };

  const onAddArticle = (value) => {
    const body = {
      ...value,
      price: {
        daily: value.daily,
        weekly: value.weekly,
        monthly: value.monthly,
        delivery: value.delivery,
        deposit: value.deposit,
      },
    };
    if (toggleModal.category) {
      updateArticle(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addArticle(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier le article" : "Ajouter un article"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddArticle}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Photo"
          name="image"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter l'image principale de la catégorie!",
            },
          ]}
        >
          <div>
            <input
              className="product_form_input_none"
              onChange={(e) => onChangeCategoryImage(e)}
              type="file"
              id="new_category_image"
              name="new_category_image"
            />
            <label htmlFor="new_category_image">
              {articleImage ? (
                <img
                  src={articleImage}
                  alt="preview logo"
                  className="product_form_image"
                />
              ) : (
                <div className="product_form_image">+</div>
              )}
            </label>
          </div>
        </Form.Item>
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label="Nom du matériel"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom du matériel!",
                },
              ]}
            >
              <Input placeholder="Pelleteuse" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Catégorie du matériel"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom du matériel!",
                },
              ]}
            >
              <Select
                placeholder="Sélectionner la catégorie du matériel"
                options={categories.map((cat) => ({
                  value: cat._id,
                  label: cat.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Prix 1 à 5 jours (HT)"
              name="daily"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez renseigner le prix pour une journée de location!",
                },
              ]}
            >
              <InputNumber
                min={1}
                placeholder="120"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Prix 6 à 20 jours (HT)"
              name="weekly"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez renseigner le prix pour une journée de location en WE!",
                },
              ]}
            >
              <InputNumber
                min={1}
                placeholder="150"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Prix à partir de 21 jours (HT)"
              name="monthly"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez renseigner le prix pour une journée de location en WE!",
                },
              ]}
            >
              <InputNumber
                min={1}
                placeholder="150"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12} />
          <Col span={12}>
            <Form.Item label="Chauffeur" name="isDriver">
              <Switch onChange={onChangeIsDriver} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Supplément avec chauffeur"
              name="driver"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez renseigner le prix du supplément avec chauffeur!",
                },
              ]}
            >
              <InputNumber
                min={0}
                placeholder="150"
                style={{ width: "100%" }}
                disabled={!isDriver}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Prix de livraison par km (HT)"
              name="delivery"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez renseigner le prix par km de la livraison!",
                },
              ]}
            >
              <InputNumber min={1} placeholder="5" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Prix de la caution"
              name="deposit"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner le prix de la caution!",
                },
              ]}
            >
              <InputNumber
                min={1}
                placeholder="500"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Poids total (PTAC)"
              name="maxWeight"
              /*rules={[
                {
                  required: true,
                  message: "Veuillez renseigner le poids total à charge!",
                },
              ]}*/
            >
              <Input placeholder="2T" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Hauteur max"
              name="maxHeight"
              /*rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la hauteur maximum total à charge!",
                },
              ]}*/
            >
              <Input placeholder="7m" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Active" name="active">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ArticleModal;
