import { createSlice } from "@reduxjs/toolkit";

export const orderReducer = createSlice({
  name: "order",
  initialState: {
    refundLoading: false,
    cancelLoading: false,
  },
  reducers: {
    refundDepositRequest: (state) => {
      state.refundLoading = true;
    },
    refundDepositSuccess: (state) => {
      state.refundLoading = false;
    },
    refundDepositFailed: (state) => {
      state.refundLoading = false;
    },
    cancelOrderRequest: (state) => {
      state.cancelLoading = true;
    },
    cancelOrderSuccess: (state) => {
      state.cancelLoading = false;
    },
    cancelOrderFailed: (state) => {
      state.cancelLoading = false;
    },
  },
});

export const {
  refundDepositRequest,
  refundDepositSuccess,
  refundDepositFailed,
  cancelOrderRequest,
  cancelOrderSuccess,
  cancelOrderFailed,
} = orderReducer.actions;

export default orderReducer.reducer;
