import axios from "axios";
import { toast } from "react-toastify";
import { CANCEL_ORDER_URL, REFUND_PAYPAL_URL } from "../api/userApi";
import {
  cancelOrderFailed,
  cancelOrderRequest,
  cancelOrderSuccess,
  refundDepositFailed,
  refundDepositRequest,
  refundDepositSuccess,
} from "../reducers/orderReducer";
import { getOrders } from "./articleActions";

export const refundDeposit = (token, id, body, onClose, dispatch) => {
  dispatch(refundDepositRequest());
  axios
    .post(`${REFUND_PAYPAL_URL}/${id}`, body, {
      headers: { "auth-token": token },
    })
    .then((res) => {
      getOrders(token, dispatch);
      onClose();
      toast(res.data.message, { type: "success" });
      dispatch(refundDepositSuccess());
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(refundDepositFailed());
    });
};

export const cancelOrder = (token, id, body, onClose, dispatch) => {
  dispatch(cancelOrderRequest());
  axios
    .post(`${CANCEL_ORDER_URL}/${id}`, body, {
      headers: { "auth-token": token },
    })
    .then((res) => {
      getOrders(token, dispatch);
      onClose();
      toast(res.data.message, { type: "success" });
      dispatch(cancelOrderSuccess());
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(cancelOrderFailed());
    });
};
