import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./reducers/adminReducer";
import articleReducer from "./reducers/articleReducer";
import categoryReducer from "./reducers/categoryReducer";
import orderReducer from "./reducers/orderReducer";
import userReducer from "./reducers/userReducer";

export default configureStore({
  reducer: {
    adminReducer: adminReducer,
    articleReducer: articleReducer,
    categoryReducer: categoryReducer,
    userReducer: userReducer,
    orderReducer: orderReducer,
  },
});
