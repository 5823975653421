import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmail } from "../../../redux/actions/adminActions";

function EmailModal(props) {
  const { token, account } = useSelector((state) => state.adminReducer);
  const { closeModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onUpdateEmail = (values) => {
    updateEmail(token, account._id, values, closeModal, dispatch);
  };

  return (
    <Modal
      title={"Modifier mon adresse Email"}
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onUpdateEmail}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nouvelle address Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner votre nouvel adresse Email'!",
            },
          ]}
        >
          <Input placeholder="casanova@gmail.com" />
        </Form.Item>
        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner votre mot de passe!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EmailModal;
