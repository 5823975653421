import { Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  AccountModal,
  EmailModal,
  PasswordModal,
} from "../../../components/mollecules";

const Profile = () => {
  const { admin, account } = useSelector((state) => state.adminReducer);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleEmailModal, setToggleEmailModal] = useState(false);
  const [togglePasswordModal, setTogglePasswordModal] = useState(false);
  return (
    <div className="border p-10">
      {toggleModal && <AccountModal closeModal={() => setToggleModal(false)} />}
      {togglePasswordModal && (
        <PasswordModal closeModal={() => setTogglePasswordModal(false)} />
      )}
      {toggleEmailModal && (
        <EmailModal closeModal={() => setToggleEmailModal(false)} />
      )}
      <div className="mb-10">
        <h2 className="mb-4 font-bold text-2xl">Mon profil</h2>
        <div className="mb-6">
          <span className="underline">Email</span> :{" "}
          <span className="font-bold">{admin.email}</span>
        </div>
        <Button
          onClick={() => setToggleEmailModal(true)}
          type="primary"
          style={{ marginRight: 20 }}
        >
          Modifier mon adresse Email
        </Button>
        <Button onClick={() => setTogglePasswordModal(true)} type="primary">
          Modifier mon mot de passe
        </Button>
      </div>
      {account && (
        <div>
          <h2 className="mb-4 font-bold text-2xl">L'entreprise</h2>
          <div className="mb-2">
            <span className="underline">Nom</span> :{" "}
            <span className="font-bold">{account.name}</span>
          </div>
          <div className="mb-2">
            <span className="underline">Adresse</span> :{" "}
            <span className="font-bold">{account.location.address}</span>
          </div>
          <div className="mb-2">
            <span className="underline">Email de contact</span> :{" "}
            <span className="font-bold">{account.email}</span>
          </div>
          <div className="mb-2">
            <span className="underline">Numéro de téléphone</span> :{" "}
            <span className="font-bold">{account.phone}</span>
          </div>
          <div className="mb-2 mb-6">
            <span className="underline">Horaire d'ouverture</span> :{" "}
            <span className="font-bold">{account.hourly}</span>
          </div>
          <Button onClick={() => setToggleModal(true)} type="primary">
            {account
              ? "Modifier les informations de l'entreprise"
              : "Ajouter les informations de l'entreprise"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Profile;
