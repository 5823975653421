import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../../redux/actions/adminActions";

function PasswordModal(props) {
  const { token, account } = useSelector((state) => state.adminReducer);
  const { closeModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onUpdatePassword = (values) => {
    updatePassword(token, account._id, values, closeModal, dispatch);
  };

  return (
    <Modal
      title={"Modifier mon mot de passe"}
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onUpdatePassword}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une adresse email de contact!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Mot de passe"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une adresse email de contact!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Mot de passe"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une adresse email de contact!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PasswordModal;
